@use "../common/variables";
@import "../common/breakpoints";

.whitepaper-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;

  .heading-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    @include px370  {
      flex-direction: column;
      align-items: self-start;
      gap: 12px;
    }

    h1 {
      font-family: "Chillax-medium";
      font-weight: 500;
      font-size: 40px;
      line-height: 56px;
      color: #3c0061;
    }

    .download-btn {
      padding: 8px 16px;
      border: 2px solid #3c0061;
      border-radius: 8px;
      cursor: pointer;
      font-family: "Chillax-medium";
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;

      color: #3c0061;
    }
  }

  .pdf-box {
    margin: 24px 0px 70px 0px;
    min-height: 800px;
    display: flex;
    flex-direction: column;

    .pdf-items {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;

      .fullscreen {
        cursor: pointer;

        svg {
          cursor: pointer;
        }
      }
    }

    .cover-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .cover {
        padding: 0px 100px;
      }
    }

    svg {
      cursor: pointer;
    }

    .clickable {
      cursor: pointer;
    }

    .pdf-pages-container {
      display: flex;
      justify-content: center;

      @include px767 {
        min-height: 400px;
      }

      .pdf-pages {
        display: flex;
        justify-content: center;
        gap: 20px;
      }
    }

    .pdf-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      margin-top: 64px;

      .pdf-current {
        display: flex;
        gap: 8px;
        align-items: center;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #767676;

        div {
          &:last-of-type {
            font-weight: 400;
          }
        }

        // .dropdown-container {
        //   position: relative;
        //   .dropdown {
        //     padding-right: 24px; /* Add enough padding for the arrow */
        //   }

        //   .dropdown:after {
        //     content: "";
        //     position: absolute;
        //     top: 50%;
        //     right: 8px; /* Adjust the position of the arrow */
        //     transform: translateY(-50%);
        //     width: 0;
        //     height: 0;
        //     border-left: 6px solid transparent;
        //     border-right: 6px solid transparent;
        //     border-top: 6px solid #000; /* Customize the color of the arrow */
        //     pointer-events: none;
        //   }

        //   .dropdown:focus:after {
        //     border-top-color: #aaa; /* Customize the color of the arrow when focused */
        //   }
        // }
      }
    }
  }
}

@include px1000 {
  .whitepaper-container {
    padding: 0px 24px;

    .heading-box {
      h1 {
        font-size: 24px;
        line-height: 32px;
      }

      .download-btn {
        font-size: 14px;
        white-space: nowrap;
      }
    }

    .pdf-box {
      .cover-container {
        .cover {
          padding: 0px 6px;
        }
      }

      // .pdf-pages-container {
      //   .pdf-pages {
      //   }
      // }

      .pdf-nav {
        gap: 16px;

        .pdf-current {
          font-size: 16px;
          // .dropdown-container {
          // }
        }
      }
    }
  }
}