@use "./variables";
@use "./breakpoints";

%custom-scrollbar {

  ::-webkit-scrollbar {
    width: 8px;
    height: auto;

    @include breakpoints.px767 {
      width: 4px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: variables.$color-dark-grey-4;
    // outline: 1px solid variables.$color-dark-grey-4;
    border-radius: variables.$border-radius-8px;
  }

  ::-webkit-scrollbar-track {
    background-color: variables.$color-light-grey-11;
    border-radius: variables.$border-radius-8px;
  }

  @-moz-document url-prefix() {
    .scroller {
      scrollbar-width: thin;
      scrollbar-color: variables.$color-dark-grey-4;
    }
  }
}