@use "../common/variables";
@use "../common/common";
@use "../common/breakpoints";

.third-gen-social-media {
  min-height: 100vh;

  &__title {
    background-color: variables.$color-maroon;

    h1 {
      color: variables.$color-light-orange;
      padding: 4.688rem 1.5rem 2rem 1.5rem;
      font-weight: 500;
      font-size: 2.5rem;
      font-family: "Chillax-medium", sans-serif;
      max-width: 1200px;
      margin: 0 auto 1.125rem auto;

      @include breakpoints.px767 {
        font-size: 24px;
        padding: 2.4rem 1.5rem 1.5rem 1.5rem;
      }
    }
  }

  &__content {

    p {
      @extend %common-text-style;
      font-size: 1rem;
    }

    h2 {
      color: variables.$color-purple;
      font-weight: 500;
      max-width: 630px;
      font-size: 2rem;
      font-family: "Chillax-medium", sans-serif;
      display: grid;
      grid-template-columns: 1fr 9fr;
      align-items: center;

      @include breakpoints.px767 {
        font-size: 1.25rem;
      }
    }

    h3 {
      @extend %common-policies-h3-style;
      font-size: 1.5rem;

      @include breakpoints.px767 {
        font-size: 1.25rem;
        margin-top: 0;
      }
    }

    ol,
    ul {
      @extend %common-text-style;
      font-size: 1rem;

      li {
        margin-left: 1.5rem;

        ul {
          margin-top: 0;
        }
      }
    }
  }

  &__generation {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 1fr;
    max-width: 1080px;
    border-radius: variables.$border-radius-12px;
    background: variables.$color-light-grey-4;
    margin: 2.5rem auto 0 auto;

    @include breakpoints.px767 {
      grid-auto-flow: row;
      grid-template-columns: unset;
      margin: 2.5rem 1.5rem 0 1.5rem;
    }

    &>div {

      &:first-of-type {
        padding: 1.5rem;
        border-radius: variables.$border-radius-12px;
        background: #d9d4ed;

        p {
          font-size: 1.25rem;
          color: variables.$color-dark-black;
          max-width: unset;
          margin: 2rem 0 0 0;
          padding: 0;

          @include breakpoints.px767 {
            font-size: 1rem;
          }
        }
      }

      &:last-of-type {
        display: flex;
        justify-content: center;
        border-radius: variables.$border-radius-12px;
        align-items: center;
        padding: 3rem 0;
      }
    }
  }

  &__social-responsibility {
    max-width: 1080px;
    margin: 2.5rem auto 0 auto;
    padding: 1.5rem 2.5rem 6.25rem 2.5rem;
    border-radius: variables.$border-radius-12px;
    background: variables.$color-purple;

    @include breakpoints.px767 {
      margin: 2.5rem 1.5rem 0 1.5rem;
      padding: 1.5rem 1.5rem 6.25rem 1.5rem;
    }

    h2 {
      color: variables.$color-light-orange;
    }

    p {
      font-size: 1.5rem;
      color: variables.$color-white;
      padding: 0;

      @include breakpoints.px767 {
        font-size: 1rem;
      }
    }
  }

  &__points {
    max-width: 1130px;
    margin: 1.8rem auto 0 auto;
    padding: 0 1.5rem;

    p,
    div {
      max-width: unset;
      margin: 1.25rem 0 0 0;
      background-color: variables.$color-light-grey-4;
      border-radius: variables.$border-radius-8px;
      display: flex;
      gap: 0.375rem;
      padding: 0.625rem;

      strong {
        font-weight: 500;
        color: variables.$color-light-orange;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: variables.$color-purple;
        border-radius: variables.$border-radius-8px;
        min-width: 2rem;
        margin: -0.625rem;
        margin-right: 0.1rem;
      }
    }

    div {
      display: block;
      position: relative;

      strong {
        height: 100%;
      }

      p {
        margin: 0;
        background-color: unset;
        border: unset;
        display: block;
        padding: 0;

        &:first-child {
          position: absolute;
          height: 100%;
        }

        &:nth-child(2) {
          margin-left: 1.8rem;
        }
      }

      ul {
        margin-top: 0;
        padding-left: 2rem;
      }
    }
  }

  &__references {
    background-color: variables.$color-light-grey-3;
    padding: 3rem 0 4rem 0;
    margin-top: 5rem;

    @include breakpoints.px767 {
    padding: 2rem 0 2.5rem 0;
    }

    strong {
      font-weight: 400;
      min-width: 1.5rem;
      text-align: right;
    }

    p {
      display: flex;
      gap: 0.375rem;
    }

    a {
      display: block;
      font-style: italic;
      color: variables.$color-sky-blue;
    }
  }
}