$screen-370px: 370px;

$screen-330px: 330px;

$screen-350px: 350px;

$screen-450px: 450px;

$screen-425px: 425px;

$screen-767px: 767px;

$screen-1000px: 1000px;

$screen-1080px: 1080px;

$screen-390px: 390px;

@mixin px370 {
  @media (max-width: $screen-370px) {
    @content;
  }
}

@mixin px330 {
  @media (max-width: $screen-330px) {
    @content;
  }
}

@mixin px350 {
  @media (max-width: $screen-350px) {
    @content;
  }
}

@mixin px450 {
  @media (max-width: $screen-450px) {
    @content;
  }
}

@mixin px425 {
  @media (max-width: $screen-425px) {
    @content;
  }
}

@mixin px767 {
  @media (max-width: $screen-767px) {
    @content;
  }
}

@mixin px1000 {
  @media (max-width: $screen-1000px) {
    @content;
  }
}

@mixin px1080 {
  @media (max-width: $screen-1080px) {
    @content;
  }
}

@mixin px1080Min {
  @media (min-width: $screen-1080px) {
    @content;
  }
}

@mixin px390 {
  @media (max-width: $screen-390px) {
    @content;
  }
}