@use "../common/variables";
@use "../common/breakpoints";

%common-small-text-style {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: variables.$color-red-1;
}

.wait-list-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(variables.$color-black, $alpha: 0.1);
  z-index: 999;
}

.wait-list-modal {
  width: clamp(380px, 20vw, 100%);
  background-color: variables.$color-white;
  border-radius: variables.$border-radius-12px;
  padding: 24px 24px;

  &__header {
    border-bottom: 1px solid #e8e5f4;
    display: flex;
    gap: 12px;
    align-items: center;
    padding-bottom: 12px;

    h3 {
      color: variables.$color-dark-black;
      font-family: "Chillax-Medium", sans-serif;
      font-weight: 500;
      font-size: 24px;
    }
  }

  &__content {
    margin-top: 20px;

    form {
      display: flex;
      flex-direction: column;
    }

    label {
      p {
        color: variables.$color-light-black;
        font-family: "Chillax-Medium", sans-serif;
      }

      input {
        background-color: variables.$color-white-2;
        color: variables.$color-light-black;
        border: 1px solid rgba($color: variables.$color-light-grey-10, $alpha: 1);
        outline: none;
        padding: 0.5rem 1rem;
        width: 100%;
        margin-top: 3px;
        border-radius: variables.$border-radius-8px;

        &::placeholder {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-style: italic;
          color: #727272;
          font-size: 0.875rem;
        }

        &:focus {
          border: 1px solid variables.$color-purple;
          background-color: variables.$color-white;
        }
      }
    }

    &__note {
      color: variables.$color-dark-grey;
      font-size: 0.688rem;
      font-family: "Poppins", sans-serif;
      margin-top: 0.2rem;
    }

    &__handle {
      display: flex;
      justify-content: space-between;

      &>span {

        &:last-of-type {
          font-size: 0.875rem;
          color: variables.$color-dark-grey;
          font-family: "Chillax-Regular", sans-serif;
        }
      }
    }

    &__submit {
      background-color: variables.$color-purple;
      border-radius: variables.$border-radius-8px;
      text-align: center;
      color: variables.$color-white;
      font-family: "Chillax-Medium", sans-serif;
      cursor: pointer;
      padding: 14px 0;
      border: 0;
      position: relative;
    }

    &__cancel {
      color: variables.$color-purple;
      font-family: "Chillax-Medium", sans-serif;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      width: max-content;
    }

    .disabled-input {
      background-color: variables.$color-light-grey-3;
      border: 1px solid rgba($color: variables.$color-light-grey-10, $alpha: 0);
      color: variables.$color-light-grey-7;
      cursor: not-allowed;
    }

    .target-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.submit-button-wrapper {
  border-top: 1px solid #e8e5f4;
  padding-top: 14px;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;

  &>* {
    flex-basis: 50%;
  }

  &>div {
    text-align: center;
  }
}

.form-field-error {
  min-height: 1.5rem;

  small {
    @extend %common-small-text-style;
    font-size: 0.688rem;
    min-height: 1.5rem;
  }
}

.error-messages {
  height: 0;
  min-height: 0;
  overflow: hidden;

  &[data-is-error="true"] {
    height: auto;
    min-height: 34px;
  }


  small {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
    background: rgba(200, 0, 0, 0.06);
    border-radius: variables.$border-radius-6px;
    display: grid;
    grid-template-columns: 1fr 9fr;
    align-items: center;
    min-height: 34px;
    @extend %common-small-text-style;
  }
}