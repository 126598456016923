@use "../common/variables";
@use "../common/breakpoints";

%cookies-consent-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(variables.$color-black, $alpha: 0.1);
  z-index: 9999;
}

.cookies-consent-container {
  @extend %cookies-consent-container;
}

.cookies-consent {
  max-width: 600px;
  background-color: variables.$color-white;
  margin: auto;
  border-radius: variables.$border-radius-16px;
  padding: 1.25rem;

  @include breakpoints.px767 {
    margin: 0;
    margin-top: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    max-width: 100%;
  }

  &__header {
    border-bottom: 1px solid variables.$color-light-grey-2;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;

    &__text {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      &>span {
        width: 35px;
        height: 35px;
        align-self: flex-end;
      }

      h2 {
        color: variables.$color-dark-black;
        font-family: "Chillax-Medium", sans-serif;
        font-weight: 500;
        white-space: nowrap;
        font-size: 1.25rem;
      }
    }

    &__close {
      margin-left: auto;
      cursor: pointer;
      transform: translateY(4px);
    }
  }

  &__para {
    color: variables.$color-dark-grey-5;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    padding: 1rem 0;
    text-align: justify;

    a {
      color: variables.$color-purple;
      font-weight: 600;
      text-decoration: none;
    }
  }

  &__footer {
    border-top: 1px solid variables.$color-light-grey-2;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding-top: 1rem;
    gap: 2.5rem;
    font-weight: 500;
    font-family: "Poppins", sans-serif;

    @include breakpoints.px767 {
      gap: 0;
      grid-template-columns: 1fr 1fr;
    }

    &__cancel {
      text-align: right;
      color: variables.$color-purple;
      cursor: pointer;

      @include breakpoints.px767 {
        text-align: center;
      }
    }

    &__save {
      background-color: variables.$color-purple;
      border-radius: variables.$border-radius-8px;
      padding: 0.625rem 0;
      color: variables.$color-white;
      text-align: center;
      cursor: pointer;
      min-width: 220px;

      @include breakpoints.px767 {
        min-width: 170px;
      }

      &>* {
        display: inline-block;
      }
    }
  }
}