@use "../common/variables";
@import "../common/breakpoints";

.contactus-container {
  max-width: 620px;
  margin: 55px auto 75px auto;
  border-radius: variables.$border-radius-16px;
  padding: 20px 24px;
  border: 2px solid variables.$color-light-grey-4;
  box-shadow: 0px 2px 6px rgba(60, 0, 97, 0.08);

  .fields-required {
    font-family: "Poppins", sans-serif;
  }

  .form-head {
    padding: 10px 0px;
    margin-bottom: 12px;
    display: flex;
    border-bottom: 1px solid variables.$color-light-grey-4;
    font-family: "Chillax-medium";

    svg {
      margin-right: 10px;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: variables.$color-dark-black;
    }
  }

  .form-body {
    padding: 0 40px;

    label {
      display: inline-block;
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 16px;
      color: variables.$color-light-black;
      font-family: "Chillax-medium";
    }
  }

  .form-footer {
    user-select: none;
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 0px;
    border-top: 1px solid variables.$color-light-grey-4;

    .footer-button {
      width: 30%;

      button {
        font-family: "Chillax-Regular", sans-serif;
        font-weight: 500;
        font-size: 16px;
      }

      .custom-transparent {
        border: 0;
        font-family: "Chillax-Medium", sans-serif;
        font-weight: 500;
        background: transparent;
        color: variables.$color-purple;
      }
    }
  }
}

@include px767 {
  .contactus-container {
    width: 90%;
    margin: 24px 24px 32px 24px;
    padding: 20px 18px;

    .form-body {
      padding: 10px 0px;
    }

    .form-head {
      padding-top: 0px;
    }

    .form-footer {
      display: flex;
      justify-content: space-between;
      padding: 20px 0px 0px;
      border-top: 1px solid variables.$color-light-grey-4;

      .footer-button {
        width: 50%;
      }
    }
  }
}