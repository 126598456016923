@use "./common/variables";
@use "./components/wait-list-modal";
@use "./common/custom_scrollbar";
@use "./common/info_note";
@use "./common/spinning_loader";
@use "./components/privacy_policy";
@use "./components/roadmap";
@use "./components/about";
@use "./components/header";
@use "./components/footer";
@use "./components/contactus";
@use "./components/report_abuse";
@use "./components/dmca";
@use "./components/generation_social_media";
@use "./components/whitepaper";
@use "./common/data_loader";
@use "./components/home";
@use "./common/success_modal";
@use "./common/common";
@use "./components/acceptable_use_policy";
@use "./components/prohibited_content_policy";
@use "./components/sonata_company_policies";
@use "./components/terms_service_for_sonata";
@use "./components/cookies_policy_sonata";
@use "./components/cookies_consent";
@use "./components/sonata_amplification_policy";
@use "./components/handle_policy";
@use "./components/custom_messages";
@use "./components/cloned_components";
@use "./components/privacy_info_breakdown";
@use "./components/press_releases";

@use "./chillax.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

html,
body {
  font-family: "Chillax-Regular", sans-serif;
  @extend %custom-scrollbar;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input::placeholder {
  color: #727272;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
}

input {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

textarea::placeholder {
  color: #727272;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
}

textarea {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.dropdown-placeholder {
  color: #727272;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    flex: 10;
  }

  img {
    flex: 1;
  }
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #727272;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #727272;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}

.custom-input,
textarea {
  width: 100%;
  padding: 10px 16px;
  background: variables.$color-white-2;
  border: 1px solid variables.$color-light-grey-10;
  border-radius: 8px;
  font-size: 14px;

  &:focus {
    outline: 1px solid variables.$color-light-grey-10;
  }
}

.custom-dropdown {
  position: relative;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid variables.$color-light-grey-10;
  border-radius: 8px;
  font-size: 14px;

  // img {
  //   position: absolute;
  //   right: 20px;
  //   top: 40%;
  // }
  .dropdown-options {
    position: absolute;
    background: variables.$color-white;
    border: 2px solid variables.$color-light-grey-10;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    z-index: 99;
    top: 42px;
    left: 0;
    width: 100%;
    font-family: "Poppins", sans-serif;

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
        border-bottom: 1px solid variables.$color-light-grey-8;
        padding: 10px;
        font-weight: 400;
        font-size: 16px;
        font-style: normal;
        color: variables.$color-light-black;

        &:hover {
          color: variables.$color-purple;
        }
      }

      li:last-child {
        border-bottom: none;
      }
    }
  }
}

.custom-button {
  width: 100%;
  padding: 10px 15px;
  background: variables.$color-purple;
  color: variables.$color-white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.checkbox-container {
  p {
    font-weight: 600;
    font-size: 16px;
    color: variables.$color-light-black;
  }

  .checkbox-div {
    display: flex;
    align-items: center;
    padding: 5px;

    p {
      font-weight: 400;
      font-size: 16px;
      color: variables.$color-light-black;
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
}

input[type="checkbox"] {
  accent-color: variables.$color-purple;
  transform: scale(1.5);
}

.fields-required {
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
  margin-top: 7px;
  color: variables.$color-dark-grey;
}

.error-text {
  display: flex;
  align-items: center;
  margin-top: 3px !important;
  color: red !important;
  font-size: 13px !important;
  font-family: "Chillax-Regular", sans-serif !important;
  font-weight: 600 !important;
  margin-left: 40px;

  svg {
    margin-right: 5px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
}

.error-text-dmca {
  display: flex;
  align-items: center;
  margin-top: 3px !important;
  color: red !important;
  font-size: 13px !important;
  font-family: "Chillax-Regular", sans-serif !important;
  font-weight: 600 !important;
  margin-left: 25px;

  svg {
    margin-right: 5px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
}

.error-dmca-3 {
  display: flex;
  align-items: center;
  margin-top: 3px !important;
  color: red !important;
  font-size: 13px !important;
  font-family: "Chillax-Regular", sans-serif !important;
  font-weight: 600 !important;

  img {
    margin-right: 5px;
  }
}