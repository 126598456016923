@use "../common/variables";
@use "../common/breakpoints";

%common-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;

  @include breakpoints.px1080 {
    grid-auto-flow: row;
    grid-template-columns: unset;
  }
}

%common-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

%common-para-text-style {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;

  @include breakpoints.px1080 {
    font-size: 1rem;
  }
}

.outer-container {
  width: auto;
}

.inner-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.home-page-heading {
  font-weight: 500;
  font-size: 3.5rem;
  font-family: 'Chillax-Medium', sans-serif;

  @include breakpoints.px1080 {
    font-size: 1.75rem;
  }

  &>span {
    display: inline-block;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      background-color: variables.$color-light-orange;
      border-radius: variables.$border-radius-8px;
      height: 5px;
      bottom: 5px;
      right: 0;
      left: 0;

      @include breakpoints.px1080 {
        height: 3px;
        bottom: 1px;
      }
    }
  }
}

.home-page-para {
  @extend %common-para-text-style;
  font-weight: 400;
  max-width: 560px;
  margin-top: 1.5rem;

  &:first-of-type {
    margin-top: 3.75rem;
  }

  @include breakpoints.px1080 {
    max-width: 380px;
    margin: 2rem auto 0 auto;

    &:first-of-type {
      margin: 2rem auto 0 auto;
    }
  }
}

.home-page-links {
  @extend %common-para-text-style;
  font-weight: 500;
  // text-decoration: none;
}

@mixin interactionImgPseudoElement($content) {
  position: absolute;
  content: "#{$content}";
  font-weight: 500;
  font-size: 1.25rem;
  font-family: 'Chillax-Medium', sans-serif;
  color: variables.$color-purple;
  background-color: variables.$color-light-orange;
  border-radius: variables.$border-radius-8px;
  padding: 0.5rem 1rem;

  @include breakpoints.px1080 {
    font-size: 1rem;
  }
}

.inner-lower-wrapper {
  display: flex;
  justify-content: flex-end;

  @include breakpoints.px1080 {
    justify-content: flex-start;
  }
}

.hero-section {
  background-image: url("../../assets/hero-image-home.png");
  @extend %common-background;

  @include breakpoints.px1080 {
    background-image: url("../../assets/hero-small.png");
  }

  &::after {
    position: absolute;
    content: "";
    background: rgba($color: variables.$color-black, $alpha: 0.32);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__inner {
    padding: 10.938rem 0;
    position: relative;
    z-index: 9;
    display: flex;
    gap: 1rem;
    align-items: center;

    @include breakpoints.px1080 {
      padding: 12rem 0 7rem 0;
      align-items: unset;
      max-width: 380px;
      margin: 0 auto;
    }

    .hero-section__sonata-logo {
      margin-left: auto;

      @include breakpoints.px1080 {
        order: 1;
        margin-left: auto;
        margin-right: auto;

        &>img {
          width: 100%;
        }
      }
    }

    .hero-section__coming-soon {
      display: flex;
      padding: 1.5rem;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      border-radius: 1rem;
      background: variables.$color-maroon;
      margin: 1.5rem auto 0 auto;
      width: max-content;
      height: 4.3125rem;

      @include breakpoints.px1080 {
        margin: 0.75rem auto 0 auto;
        gap: 1rem;
        height: 2.6875rem;
        border-radius: 0.75rem;
        padding: 1rem;
      }

      &__social {
        font-family: 'Chillax-Medium', sans-serif;
        color: variables.$color-light-orange;
        font-size: 2rem;
        font-weight: 500;

        @include breakpoints.px1080 {
          font-size: 1rem;
        }
      }

      &__soon {
        font-family: 'Chillax-Medium', sans-serif;
        color: variables.$color-white;
        font-size: 2rem;
        font-weight: 500;

        @include breakpoints.px1080 {
          font-size: 1rem;
        }
      }
    }

    .hero-section__icons {
      display: flex;
      gap: 3rem;
      align-items: center;
      justify-content: center;
      margin: 1.76rem auto 0 auto;

      &>svg {
        cursor: pointer;
      }

      @include breakpoints.px1080 {
        margin: 1rem auto 0 auto;
        gap: 2rem;
      }
    }

    .wait-list-modal {
      max-width: 380px;

      @include breakpoints.px1080 {
        order: 2;
        position: absolute;
        bottom: -26.5em;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        box-shadow: 0px 2px 6px 0px rgba(60, 0, 97, 0.08);
      }
    }

    .wait-list-modal__content__submit {
      flex: 1;
      font-size: 1rem;
    }
  }
}

.interactions {
  background-color: variables.$color-purple;

  .interactions-wrapper {
    padding: 5rem 0;

    @include breakpoints.px1080 {
      padding: 3rem 0 2.5rem 0;
    }
  }

  &__inner {
    @extend %common-grid;

    @include breakpoints.px1080 {
      align-items: unset;
      max-width: 380px;
      margin: 0 auto;
      position: relative;
    }

    &__img-note {
      margin: 0 auto;

      @include breakpoints.px1080 {
        margin-top: 4.25rem;
        position: relative;
        display: inline-block;

        &::before {
          content: url("../../assets/arrow-down-small.svg");
          position: absolute;
          bottom: -3.3em;
          left: 36.9%;
        }

        &::after {
          content: url("../../assets/large-curve-mbl.svg");
          position: absolute;
          bottom: -29.8em;
          right: -4px;
        }

        &>img {
          width: 100%;
          height: 737px;
        }
      }
    }

    .home-page-heading,
    .home-page-para {
      color: variables.$color-white;
    }
  }

  &__lower {
    @extend %common-grid;
    margin-top: 2.31rem;

    @include breakpoints.px1080 {
      max-width: 380px;
      margin: 3.37rem auto 0 auto;
    }

    .renote-img {
      justify-content: center;
    }

    &__img-re-note {
      position: relative;
      display: inline-block;

      &>img {
        margin-top: -10px;
      }

      @include breakpoints.px1080 {
        margin-top: 4.688rem;

        &>img {
          width: 100%;
        }
      }

      @include breakpoints.px350 {

        &>img {
          margin-top: 26px;
        }
      }

      &::before {
        position: absolute;
        content: url("../../assets/curve-arrow-down.svg");
        top: -10.6em;
        left: 47%;

        @include breakpoints.px1080 {
          content: unset;
        }
      }
    }

    &__img-comment {
      position: relative;
      display: inline-block;

      &>img {
        margin-top: -7px;
      }

      @include breakpoints.px1080 {

        &>img {
          width: 100%;
        }
      }

      &::before {
        position: absolute;
        content: url("../../assets/curve-arrow-down-large.svg");
        top: -10.6em;
        left: 47%;

        @include breakpoints.px1080 {
          content: unset
        }
      }
    }
  }
}

.freedom {
  @extend %common-background;

  @include breakpoints.px1080Min {
    background-image: url("../../assets/freedom.png");
  }

  &__mbl {
    @extend %common-background;
  }

  @include breakpoints.px1080 {
    &__mbl {
      height: 270px;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background-image: url("../../assets/freedom-small.png");
    }
  }

  &::after {
    content: "";
    background-image: url("../../assets/linearbg.png");
    @extend %common-background;
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;

    @include breakpoints.px1080 {
      background-image: url("../../assets/linear-freedom-small.png");
    }
  }

  &__inner {
    position: relative;
    z-index: 9;
    padding: 5rem 0 25rem 0;

    @include breakpoints.px1080 {
      padding: 2.5rem 0 12.75rem 0;
      max-width: 380px;
      margin: 0 auto;
    }

    .home-page-heading,
    .home-page-para,
    .home-page-links {
      color: variables.$color-white;
    }
  }
}

.social-media {
  background-color: variables.$color-white;

  .social-media-wrapper {
    padding: 6.25rem 0 10rem 0;

    @include breakpoints.px1080 {
      padding: 2.5rem 0 3.5rem 0;
      max-width: 380px;
      margin: 0 auto;
    }

    .home-page-heading,
    .home-page-links {
      color: variables.$color-purple;
    }

    .home-page-para {
      color: variables.$color-light-black;
    }
  }

  &__inner {
    @extend %common-grid;

    @include breakpoints.px1080 {

      .inner-lower-wrapper {
        justify-content: center;

        &>img {
          width: 100%;
        }
      }
    }

    &__img {
      transform: translateY(3em);

      @include breakpoints.px1080 {
        transform: unset;
        margin-top: 2rem;
      }
    }
  }
}

.based-in {
  background-color: #eae4d9;

  &__inner {
    padding: 6.25rem 0 15rem 0;
    @extend %common-grid;

    @include breakpoints.px1080 {
      padding: 2.5rem 0;
      max-width: 380px;
      margin: 0 auto;

      .inner-lower-wrapper {
        justify-content: center;

        &>img {
          width: 100%;
        }
      }
    }

    .home-page-heading {
      color: variables.$color-purple;
    }

    .home-page-para {
      color: variables.$color-light-black;
      max-width: 480px;
    }

    &__img {
      transform: translateY(7em);

      @include breakpoints.px1080 {
        transform: unset;
        margin-top: 1.5rem;
      }
    }
  }
}

.no-investor {
  @extend %common-background;

  @include breakpoints.px1080Min {
    background-image: url("../../assets/no-investor.png");
  }

  &__mbl {
    @extend %common-background;
  }

  @include breakpoints.px1080 {

    &__mbl {
      height: 315px;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background-image: url("../../assets/no-investor-small.png");
    }
  }

  &::after {
    content: "";
    background-image: url("../../assets/linear-bg-small.png");
    @extend %common-background;
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;

    @include breakpoints.px1080 {
      background-image: url("../../assets/linear-investor-small.png");
    }
  }

  &__inner {
    position: relative;
    z-index: 9;
    padding: 5rem 0 17.5rem 0;

    @include breakpoints.px1080 {
      padding: 2.5rem 0 12.5rem 0;
      max-width: 380px;
      margin: 0 auto;
    }

    .home-page-heading,
    .home-page-para,
    .home-page-links {
      color: variables.$color-white;
    }
  }
}

.discord {
  background-color: variables.$color-blue;

  &__wrapper {
    position: relative;
    padding: 4.25rem;

    @include breakpoints.px1080 {
      padding: 4.25rem 0;
    }

    &>svg {
      position: absolute;
      right: 2.75em;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__join {
    display: flex;
    justify-content: center;

    &>a {
      border: 2px solid variables.$color-white;
      color: variables.$color-white;
      font-size: 1.25rem;
      font-family: 'Chillax-Medium', sans-serif;
      border-radius: variables.$border-radius-8px;
      text-decoration: none;
      padding: 0.5rem 0.75rem;
      max-width: 346px;
      display: grid;
      align-items: center;
      grid-template-columns: auto auto;
      gap: 1.9rem;
      transition: 0.3s background-color linear;

      &:hover {
        background-color: #7984F5;
      }

      @include breakpoints.px1080 {
        font-size: 1rem;
      }

      @include breakpoints.px330 {
        gap: 1rem;
      }
    }
  }
}

.acronym-banner {

  p {
    color: variables.$color-purple;
    padding: 2rem 0;
    font-size: 2rem;
    font-family: 'Chillax-Medium', sans-serif;
    font-weight: 500;
    text-align: center;

    span {
      color: variables.$color-light-orange;
    }

    @include breakpoints.px1080 {
      padding-top: 28rem;
      max-width: 380px;
      margin: 0 auto;

      &>span {
        display: block;
        text-align: left;
        color: variables.$color-purple;

        span {
          color: variables.$color-light-orange;
        }
      }
    }
  }
}