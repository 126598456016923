@use "./variables";
@use "./breakpoints";

.success-modal{
  width: clamp(380px, 20vw, 100%);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: variables.$color-white;
  border-radius: variables.$border-radius-12px;
  gap: 10px;
  animation: success-modal 0.3s forwards;
  position: fixed;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, 50%);

  @include breakpoints.px1080 {
    width: 90%;
    max-width: 380px;
  }

  @keyframes success-modal {
    from {
      bottom: -50%;
    }

    to {
      bottom: 50%;
    }
  }

  &__para1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: variables.$color-purple;
  }

  &__para2 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: variables.$color-dark-grey;
  }
}