@use "../common/variables";
@import "../common/breakpoints";

.report-abuse {
  padding: 20px 1.5rem;
  max-width: 1200px;
  margin: 0 auto;

  .report-top {

    h1 {
      color: variables.$color-purple;
      font-weight: 500;
      font-size: 64px;
      font-family: "Chillax-medium";
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: variables.$color-dark-grey;
      font-family: "Poppins", sans-serif;
    }
  }

  .report-form-container {
    max-width: 620px;
    margin: 45px auto;
    border-radius: variables.$border-radius-16px;
    padding: 20px 24px;
    border: 2px solid variables.$color-light-grey-4;
    box-shadow: 0px 2px 6px rgba(60, 0, 97, 0.08);

    .fields-required {
      font-family: "Poppins", sans-serif;
    }

    .form-head {
      padding: 10px 0px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid variables.$color-light-grey-4;
      font-family: "Chillax-medium";

      svg {
        margin-right: 10px;
      }

      span {
        font-weight: 500;
        font-size: 18px;
        color: variables.$color-dark-black;
      }
    }

    .form-body {
      padding: 10px 40px;

      label {
        display: inline-block;
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 16px;
        color: variables.$color-light-black;
        font-family: "Chillax-medium";
      }

      .custom-dropdown {
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        color: #727272;
        border: 2px solid #C6BEE3;
        cursor: pointer;

        .dropdown-options {
          top: 52px;
          padding: 1rem;
        }

        li {

          &:nth-child(1) {
            padding: 0 0 1rem 0;
          }

          &:nth-child(2) {
            padding: 1rem 0;
          }

          &:nth-child(3) {
            padding: 1rem 0 0 0;
          }
        }
      }
    }

    .form-footer {
      user-select: none;
      display: flex;
      justify-content: space-between;
      padding: 20px 0px 0px;
      border-top: 1px solid variables.$color-light-grey-4;

      .footer-button {
        width: 30%;

        button {
          font-family: "Chillax-Regular", sans-serif;
          font-weight: 500;
          font-size: 16px;
          width: max-content;
        }

        .continue {
          width: 100%;
        }
      }
    }
  }
}

@include px767 {
  .report-abuse {
    padding: 18px 24px 34px 24px;

    .report-top {
      h1 {
        font-size: 32px;
        padding-bottom: 18px;
      }
    }

    .report-form-container {
      width: 100%;
      margin: 0;
      padding: 20px 18px;

      .form-head {
        padding-top: 0px;
      }

      .fields-required {
        margin-top: 12px;
        margin-bottom: 10px;
      }

      .form-body {
        padding: 10px 0px;
      }

      .form-footer {
        margin-top: 10px;
        padding-top: 16px;

        .footer-button {
          width: 50%;
        }
      }
    }
  }
}