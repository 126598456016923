@use "../common/variables";
@use "../common/common";
@import "../common/breakpoints";

.about-page {

  &__cover {
    background-image: url("../../assets/about-cover.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    position: relative;

    p {
      padding: 4.5rem 0 7rem 0;

      @include px767 {
        padding: 1rem 0 1.6rem 0;
      }
    }

    svg {
      position: relative;
      z-index: 9;
    }

    &::after {
      position: absolute;
      content: "";
      background-color: rgba(0, 0, 0, 0.5);
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  .tagline {
    background-color: variables.$color-purple;
    max-width: unset;
    margin: 0;
    padding: 0;

    &>span {
      max-width: 1200px;
      margin: 0 auto;
      padding: 3rem 1.5rem;
      display: block;
      font-size: 1.5rem;
      text-align: center;
      color: variables.$color-white;
      font-family: "Chillax-Medium", sans-serif;

      @include px767 {
        max-width: 300px;
        padding: 2rem 1.5rem;
      }

      span {
        color: variables.$color-light-orange;
      }
    }
  }

  .sonata-icon {
    max-width: 1290px;
    margin: 0 auto;
    padding: 1.5rem 1.5rem 0 1.5rem;

    @include px767 {
      padding: 1.5rem 1.5rem 0 0.625rem;
    }
  }

  .sonata-title {
    font-weight: 600;
    font-size: 40px;
    font-family: "Chillax-Regular", sans-serif;
    color: variables.$color-purple;

    @include px767 {
      font-size: 2rem;
    }
  }

  .sonata-creator {

    span {
      font-style: italic;
      color: variables.$color-dark-black;

      &:first-of-type {
        font-weight: 500;
      }

      &:last-of-type {
        font-weight: 400;
      }
    }
  }

  .about-page-heading {
    max-width: 1290px;
    margin: 4.625rem auto 3rem auto;
    padding: 0 1.5rem;

    @include px1000 {
      padding-left: 0;
    }

    h2 {
      font-family: 'Chillax-Semibold';
      font-weight: 600;
      font-size: 2.625rem;
      background: variables.$color-purple;
      border-radius: variables.$border-radius-12px;
      width: max-content;
      padding: 1.25rem 4rem 1.25rem 2.5rem;
      color: variables.$color-white;
      position: relative;

      @include px1000 {
        font-size: 1.5rem;
        padding: 0.75rem 3rem 0.75rem 1.625rem;
        border-radius: variables.$border-radius-8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        word-break: break-word;
        width: auto;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        border-radius: variables.$border-radius-12px;
        box-shadow: 1.25rem 0 0 0 variables.$color-light-orange, -1.25rem 0 0 0 variables.$color-light-orange;

        @include px1000 {
          box-shadow: 0.75rem 0 0 0 variables.$color-light-orange;
          border-radius: variables.$border-radius-8px;
        }
      }
    }
  }

  &__content {

    p {
      @extend %common-text-style;
      font-size: 1.5rem;

      @include px767 {
        font-size: 1.25rem;
      }

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .sonata-philosophy {
      background: #faf8f5;
      margin-top: 4.5rem;

      &>p {
        color: variables.$color-maroon;
        text-align: center;
        font-family: "Chillax-Semibold", sans-serif;
        font-size: 42px;
        padding-top: 2rem;
        margin-bottom: 0;

        @include px767 {
          font-size: 1.75rem;
          padding-top: 1.75rem;
        }

        span {
          color: variables.$color-light-orange;
        }
      }

      &__inner {
        max-width: 1200px;
        margin: 2rem auto 0 auto;
        padding: 0 1.5rem 3.75rem 1.5rem;
        display: grid;
        gap: 0.625rem;
        grid-auto-flow: column;
        grid-template-columns: repeat(3, auto);

        @include px767 {
          margin-top: 1.5rem;
          display: block;
        }

        @mixin gridArea($n, $area) {
          &:nth-child(#{$n}) {
            grid-area: $area;
          }
        }

        &>p {
          font-family: "Chillax-Regular", sans-serif;
          font-size: 1.75rem;
          color: variables.$color-purple;
          max-width: unset;
          margin: 0;
          border-radius: variables.$border-radius-12px;
          border: 4px solid variables.$color-light-orange;
          padding: 1.5rem;

          @include px767 {
            font-size: 1.375rem;
            padding: 1rem;
          }

          span {
            font-family: 'Chillax-Bold', sans-serif;
          }

          @include gridArea(1, 1 / 1 / span 1 / span 3);
          @include gridArea(2, 2 / 1 / span 2 / span 1);
          @include gridArea(3, 2 / 2 / span 2 / span 1);
          @include gridArea(4, 2 / 3 / span 1 / span 1);
          @include gridArea(5, 4 / 1 / span 1 / span 2);
          @include gridArea(6, 3 / 3 / span 2 / span 1);
          @include gridArea(7, 5 / 1 / span 1 / span 3);

          @include px767 {
            margin-top: 0.625rem;
          }
        }
      }
    }

    ul {
      @extend %common-text-style;
      font-size: 1.5rem;

      @include px767 {
        font-size: 1.25rem;
      }

      li {
        font-weight: 600;
        margin-top: 1.5rem;
        margin-left: 2rem;
      }
    }

    .monetisation-list {

      p {
        padding-left: 3.5rem;
      }
    }

    .future-list {

      ul {

        li {
          font-weight: 400;
        }
      }
    }
  }
}