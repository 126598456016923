// colors
$color-purple: #3c0061;
$color-purple-dark: #5d4180;
$color-light-purple: #8e8694;
$color-light-purple-1: #e6dcf3;
$color-maroon: #330320;

$color-white: #fff;
$color-white-2: #fafafd;
$color-off-white-1: #f5f5f5;

$color-light-grey-1: #fcfbfd;
$color-light-grey-2: #f3eef9;
$color-light-grey-3: #fbfbfb;
$color-light-grey-4: #f6f5fb;
$color-light-grey-5: #bbbbbb;
$color-light-grey-6: #999999;
$color-light-grey-7: #868686;
$color-light-grey-8: #e7e7e7;
$color-light-grey-9: #e3e3e3;
$color-light-grey-10: #c6bee3;
$color-light-grey-11: #f4f4f4;

$color-dark-grey: #767676;
$color-dark-grey-1: #959595;
$color-dark-grey-2: #ccc5e6;
$color-dark-grey-4: #aaaaaa;
$color-dark-grey-5: #777777;
$color-dark-grey-6: #707070;

$color-black: #000;
$color-dark-black: #160323;
$color-light-black: #444444;

$color-red: #f22424;
$color-red-1: #c82020;
$color-light-red: #f5d0d0;
$color-red-1: #C80000;

$color-light-orange: #fd5201;

$color-green: #3fab2e;
$color-green-1: #0fa958;
$color-light-green: #cce5d8;

$color-light-blue: #e1ebfd;
$color-blue: #5865F2;
$color-sky-blue: #0081E6;

$color-bread-borderBottom: #c6bee3;

// border radius
$border-radius-12px: 12px;
$border-radius-8px: 8px;
$border-radius-4px: 4px;
$border-radius-16px: 16px;
$border-radius-6px: 6px;
$border-radius-18px: 18px;
