@use "./variables";

.spinning-loader {
  display: inline-block;
  position: absolute;
  left: 0.5em;
  border: 2px solid rgba($color: variables.$color-white, $alpha: 1.0);
  border-top: 2px solid variables.$color-light-orange;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}