@use "../common/variables";
@use "../common/common";
@use "../common/breakpoints";

.acceptable-use-policy {

  &__content {
    margin: 3rem 0 5rem 0;

    p {
      @extend %common-text-style;
      font-size: 1rem;

      &:first-child {
        font-weight: 600;
        font-size: 1.25rem
      }
    }

    h3 {
      @extend %common-policies-h3-style ;
    }

    a {
      color: variables.$color-sky-blue;
    }

    em {
      color: variables.$color-dark-grey;
    }

    ol,
    ul {
      @extend %common-text-style;
      font-size: 1rem;

      li {
        margin-left: 1.5rem;

        ul {
          margin-top: 0;
        }
      }
    }
  }
}