@use "./breakpoints";
@use "./variables";
@use "./custom_scrollbar";

%common-text-style {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: variables.$color-light-black;
  max-width: 1200px;
  margin: 1rem auto 0 auto;
  padding: 0 1.5rem;
  word-break: break-word;
}

%common-policies-h3-style {
  font-weight: 500;
  font-family: "Chillax-medium", sans-serif;
  font-size: 1.125rem;
  color: variables.$color-dark-black;
  max-width: 1200px;
  margin: 1rem auto 0 auto;
  padding: 0 1.5rem;
}

%common-text-style-2 {
  font-size: 1.5rem;
  font-family: "Chillax-Regular", sans-serif;
  font-weight: 400;

  @include breakpoints.px767 {
    font-size: 1.25rem;
  }
}

%toc-heading {
  @extend %common-text-style;
  font-weight: 600;
  color: variables.$color-light-black;
  font-size: 1.5rem;

  @include breakpoints.px767 {
    font-size: 1.375rem;
  }
}

.policy-table {
  max-width: 1200px;
  margin: 2rem auto 2.5rem auto;
  padding: 0 1.5rem;

  @include breakpoints.px767 {
    overflow-x: auto;
    margin: 2rem 1.5rem 2.5rem 1.5rem;
    padding: 0;
  }
}

%policy-table {
  border-collapse: collapse;
  border: 1px solid variables.$color-light-black;

  @include breakpoints.px767 {
    margin-bottom: 1rem;
  }

  %common-table-text-style {
    font-family: "Poppins", sans-serif;
    color: variables.$color-light-black;
    border: 2px solid variables.$color-light-black;
    padding: 0.5rem;
    vertical-align: top;
  }

  th {
    font-weight: 600;
    font-size: 1.125rem;
    @extend %common-table-text-style;

    @include breakpoints.px767 {
      font-size: 0.875rem;
    }
  }

  td {
    font-weight: 400;
    font-size: 1rem;
    @extend %common-table-text-style;

    @include breakpoints.px767 {
      font-size: 0.75rem;
    }
  }
}

%common-policies-h4-style {
  @extend %common-text-style;
  font-size: 1rem;
  font-weight: 600;
}

.p-title {
  max-width: 1200px;
  margin: 2.5rem auto 0 auto;
  padding: 0 1.5rem;
  font-weight: 500;
  font-size: 4rem;
  color: variables.$color-purple;
  font-family: "Chillax-medium", sans-serif;

  @include breakpoints.px767 {
    font-size: 2rem;
    margin: 2rem auto 0 auto;
  }
}

.effective-date {
  max-width: 1200px;
  margin: 1.25rem auto 0 auto;
  padding: 0 1.5rem;

  time {
    @extend %common-text-style-2;
    color: variables.$color-dark-black;

    @include breakpoints.px767 {
      font-size: 1.125rem;
    }
  }
}

.policy-subtitle {
  max-width: 1200px;
  margin: 1.25rem auto 0 auto;
  padding: 0 1.5rem;

  h3 {
    @extend %common-text-style-2;
    color: variables.$color-dark-grey;

    @include breakpoints.px767 {
      font-size: 1.125rem;
    }
  }
}

.download-pdf {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoints.px767 {
    flex-direction: column;
    gap: 1rem;
    justify-content: unset;
    align-items: flex-start;
  }
}

.policy-heading {
  max-width: 1270px;
  margin: 2.5rem auto 0 auto;
  padding: 0 1.5rem;

  @include breakpoints.px767 {
    padding: 0 1.5rem 0 0;
  }

  h2,
  h3 {
    background: variables.$color-white-2;
    color: variables.$color-purple;
    font-weight: 500;
    font-family: "Chillax-medium", sans-serif;
    padding: 0.625rem 2.5rem 0.625rem 2rem;
    font-size: 1.25rem;
    border-radius: 0 variables.$border-radius-8px variables.$border-radius-8px 0;
    width: max-content;
    border-left: 4px solid variables.$color-purple;

    @include breakpoints.px767 {
      font-size: 1.125rem;
      width: auto;
    }
  }
}

.p-table-of-contents {
  max-width: 1200px;
  margin: 1rem auto 0 auto;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  a {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.125rem;
    text-decoration: none;
    color: variables.$color-light-black !important;
    border: 2px solid variables.$color-purple;
    border-radius: variables.$border-radius-8px;
    background-color: variables.$color-white-2;
    padding: 0.375rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;

    @include breakpoints.px767 {
      font-size: 1rem;
    }
  }
}

.downloading-pdf {
  max-width: 1200px;
  margin: 1rem auto 0 auto;
  padding: 0 1.5rem;

  div {
    margin-left: auto;
    margin-right: auto;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: variables.$color-dark-black;
    font-size: 1.25rem;
    width: max-content;
    position: relative;

    .spinning-loader {
      top: 5px;
      left: -1.25em;
      transform: translateX(40%);
      border: 2px solid variables.$color-dark-grey-2;
      border-top: 2px solid variables.$color-light-orange;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.pdf-download-btn {
  padding: 0.5rem 1rem;
  background: transparent;
  color: variables.$color-purple;
  border: 2px solid variables.$color-purple;
  font-family: "Chillax-Medium", sans-serif;
  font-weight: 500;
  border-radius: variables.$border-radius-8px;
  cursor: pointer;
  font-size: 1rem;

  @include breakpoints.px767 {
    width: 100%;
  }
}

.clone-comp {

  .p-title {
    font-size: 4rem;
    margin: 2.5rem auto 0 auto;
  }

  .effective-date time {
    font-size: 1.5rem;
  }

  .policy-heading {
    padding: 0 1.5rem;

    h2,
    h3 {
      width: max-content;
      font-size: 1.25rem;
    }
  }

  .policy-table {
    margin: 2rem auto 2.5rem auto;
    padding: 0 1.5rem;
    overflow: unset;
  }

  table th {
    font-size: 1.125rem;
  }

  table td {
    font-size: 1rem;
  }
}