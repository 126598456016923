@use "../common/variables";

#cloned-component {

  &>div:first-child {
    width: 1000px;
    position: absolute;
    right: 0;
    left: 0;
    background: variables.$color-white;
    top: -2000em;
  }

  &>div:first-child.cookie-policy-sonata {
    width: 1200px;
  }
}