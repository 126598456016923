@use "../common/variables";
@use "../common/breakpoints";

.custom-messages {
  margin: 6.25rem auto;
  max-width: 900px;
  
  @include breakpoints.px767 {
    margin: 9.5rem auto;
  }
  
  &[data-is-page-not-found="true"] {
    max-width: 560px;

    .custom-message__para1 {
      color: variables.$color-light-black;
      font-weight: 600;
      margin-top: 4.875rem;
      font-size: 2rem;
    }

    .custom-message__para2 {
      margin-top: 3.75rem;
      font-size: 1.25rem;
    }
  }
}

.custom-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @include breakpoints.px767 {
    padding: 0 3.125rem;
  }

  &__para1 {
    margin-top: 3.625rem;
    font-size: 3rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: variables.$color-dark-black;

    &[data-is-chillax-font="true"] {
      font-family: "Chillax-medium", sans-serif;
    }

    &[data-is-email-verified-res="true"] {
      color: variables.$color-purple;
    }

    @include breakpoints.px767 {
      font-size: 1.5rem;
      margin-top: 3.125rem;
    }
  }

  &__para2 {
    font-size: 2rem;
    margin-top: 2.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: variables.$color-dark-grey;

    @include breakpoints.px767 {
      font-size: 1rem;
      margin-top: 2.5rem;
    }
  }
}