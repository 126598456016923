@use "../common/variables";
@import "../common/breakpoints";

.footer-container {
  background-color: variables.$color-maroon;

  &__inner {
    padding: 0 1.5rem;
    max-width: 1200px;
    margin: 0 auto;

    @include px1080 {
      padding: 0;
    }
  }

  .footer-links {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 2.5rem 4rem 4.688rem 4rem;

    @include px1080 {
      padding: 0;
    }

    .logo {
      padding-top: 2.5rem;
    }

    .links {
      user-select: none;
      display: flex;
      gap: 4rem;

      .footer-divs {

        h5 {
          font-weight: 500;
          font-size: 20px;
          color: variables.$color-white;
          font-family: "Chillax-medium";
          min-width: max-content;
        }

        div {
          padding-top: 0.875rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          a {
            text-decoration: none;
            font-family: 'Chillax-Regular', sans-serif;
            font-size: 16px;
            color: variables.$color-white;
            font-weight: 400;
            min-width: max-content;
          }
        }
      }
    }
  }

  .copyright {
    padding: 20px 0px;

    p {
      color: variables.$color-white;
      font-weight: 400;
      font-size: 16px;
      font-family: "Poppins";
    }
  }

  .footer-join-discord {
    border: 1px solid;
    border-color: #5C354D;
    border-radius: variables.$border-radius-8px;
    padding: 0.75rem 1.5rem 1.5rem 1.5rem;
    text-align: center;
    transition: 0.3s border-color linear;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: max-content;
    align-items: center;

    &:hover {
      border-color: variables.$color-white
    }

    @include px330 {
      padding: 0.75rem 1rem 1.25rem 1rem;
    }

    &>span {

      &:first-of-type {
        color: variables.$color-white;
        font-family: 'Chillax-Medium', sans-serif;
        font-size: 1.25rem;
        min-width: max-content;
      }
    }
  }
}

@include px1080 {
  .footer-container {
    padding: 3.125rem 2.5rem;

    .footer-links {
      display: block;

      .logo {
        padding: 30px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }

      .links {
        display: flex;
        justify-content: unset;
        flex-direction: column;
        gap: 2.5rem;
        padding: 4rem 0 0.625rem 0;

        .footer-join-discord {
          padding: 0.75rem 0.75rem 1.25rem 0.75rem;
          margin: 0 1.5rem;
          width: 7.25rem;
          height: 7.75rem;
        }

        &>div {
          margin: 0 1.5rem;
        }

        .footer-divs {
          margin: 20px 0px;

          @include px425 {

            a {
              word-break: break-word;
            }
          }

          &:first-of-type {
            margin: 0 0 20px 0;
          }
        }
      }
    }
  }
}