@use "../common/variables";
@import "../common/breakpoints";

.header-container {
  background-color: variables.$color-purple;

  &__inner {
    padding: 1.25rem 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    display: flex;
    align-items: center;

    &>a {
      display: flex;
    }

    img {
      padding-right: 20px;
    }

    h4 {
      padding-left: 20px;
      color: variables.$color-white;
      font-weight: 500;
      font-size: 16px;
      font-family: "Chillax-medium", sans-serif;
    }
  }

  .desktop-links {
    user-select: none;
    display: flex;
    gap: 1rem;
    align-items: center;

    &>div {

      &>a,
      &>span,
      .active-home {
        text-decoration: none;
        font-family: "Chillax-Medium", sans-serif;
        font-size: 16px;
        color: variables.$color-white;
        padding: 0.5rem 1rem;
        border-radius: variables.$border-radius-8px;
      }

      .active-background {
        background-color: variables.$color-white;
        color: variables.$color-purple;
      }

      &>span,
      .active-home {
        position: relative;
        display: inline-block;
        cursor: pointer;

        &[data-is-active-dropdown-link="true"] {
          background-color: variables.$color-white;
          color: variables.$color-purple;

          svg {
            path {
              fill: variables.$color-purple;
            }
          }
        }

        &>div {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
          padding-top: 2rem;
          min-width: 100%;

          &>div {
            margin-top: -1rem;
            border: 2px solid variables.$color-light-grey-8;
            background-color: variables.$color-white;
            border-radius: variables.$border-radius-8px;
            padding: 0.75rem 0.5rem;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            width: max-content;
            min-width: 100%;

            &>a {
              font-weight: 400;
              color: variables.$color-light-black;
              font-family: "Poppins", sans-serif;
              text-decoration: none;
              padding: 0.25rem 0.625rem;
              border-radius: variables.$border-radius-8px;

              &:hover {
                background-color: variables.$color-light-grey-4;
              }
            }
          }
        }

        .active-dropdown {
          background-color: variables.$color-light-grey-4;
        }
      }

      .whitepaper {
        border-radius: variables.$border-radius-8px;
        border: 2px solid variables.$color-white;
      }

      svg {
        margin-left: 8px;
      }

      .active-home {
        color: variables.$color-purple;
        background-color: variables.$color-white;

        svg {
          path {
            fill: variables.$color-purple;
          }
        }
      }

      .active-li {
        color: variables.$color-purple;
        background-color: variables.$color-white;

        .mbl-li {
          background-color: variables.$color-light-black;

          svg {
            transform: rotate(180deg);
          }
        }

        svg {
          transform: rotate(180deg);

          path {
            fill: variables.$color-purple;
          }
        }
      }
    }
  }
}

.waitlist-alert-container {
  background: #eae4d9;

  &__inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 12px 1.5rem;
    align-items: center;

    @include px767 {
      flex-direction: column;
    }
  }

  &__mbl {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .cross-icon {
    display: flex;
    cursor: pointer;

    @include px767 {
      display: flex;
      cursor: pointer;
    }
  }

  .wait-list-text {
    font-size: 14px;
    font-family: "Chillax-Medium", sans-serif;
    color: #160323;

    @include px767 {
      text-align: center;
      font-size: 14px;
    }
  }

  .waitlist-button {
    user-select: none;
    display: flex;
    padding: 4px 12px;
    background: #330320;
    border-radius: 6px;
    font-family: "Chillax-Medium", sans-serif;
    font-size: 14px;
    color: #ffffff;
    align-items: center;
    cursor: pointer;
    gap: 5.5px;

    @include px767 {
      width: 100%;
      justify-content: center;
      margin-top: 8px;
      font-size: 14px;
    }

    span {
      display: flex;
    }
  }
}

.sidebar-container {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  .mobile-links {
    width: 60%;
    margin-left: auto;
    font-family: "Chillax-Medium", sans-serif;
    padding: 0 24px 20px 24px;
    background-color: variables.$color-white;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100vh;
    overflow: auto;

    &>a,
    &>span {
      text-decoration: none;
      padding: 8px 12px;
      font-weight: 500;
      font-size: 16px;
      color: variables.$color-purple;
      border-radius: variables.$border-radius-8px
    }

    &>span {

      .dropdown-menu {

        &>div {
          display: flex;
          margin-top: 0.9rem;
          flex-direction: column;
          padding: 12px 8px;
          gap: 1rem;
          border-radius: variables.$border-radius-8px;
          border: 2px solid #E7E7E7;

          &>a {
            font-weight: 400;
            color: variables.$color-light-black;
            font-family: "Poppins", sans-serif;
            text-decoration: none;
            padding: 8px 12px;
            border-radius: variables.$border-radius-8px;
          }
        }
      }
    }

    .whitepaper-mbl {
      border-radius: variables.$border-radius-8px;
      border: 2px solid variables.$color-purple;
      text-align: center;
    }
  }

  .mbl-li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0;
  }

  .active-li {

    .mbl-li {

      svg {
        transform: rotate(180deg);
      }
    }
  }

  .active-mbl-link {
    background-color: variables.$color-light-grey-4;
    color: variables.$color-purple !important;
  }
}

.toggle-sidebar {
  cursor: pointer;

  &>svg {
    transform: translate(1px, 3px);
  }

  &[data-is-menu-open="true"] {
    margin: 1.5rem 0 4rem 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}