@use "../common/variables";
@use "../common/breakpoints";
@use "../common/common";

.handle-policy {

  &__content {
    margin: 3rem 0 6.25rem 0;

    @include breakpoints.px767 {
      margin: 3rem 0 4rem 0;
    }

    p {
      @extend %common-text-style;
      font-size: 1rem;
    }

    em {
      color: variables.$color-dark-grey;
    }

    ol,
    ul {
      @extend %common-text-style;
      font-size: 1rem;

      li {
        margin-left: 1rem;
        margin-top: 0.5rem;
      }
    }

    .acceptable-language {
      display: block;
      font-size: 0.75rem;
    }
  }
}