@use "../common/variables";
@use "../common/common";
@use "../common/breakpoints";

.press-releases {

  &__titles {
    margin-top: 2.88rem;

    @include breakpoints.px767 {
      margin-top: 2rem;
    }

    .p-table-of-contents {

      a {
        max-width: 100%;
        justify-content: unset;
        background-color: variables.$color-light-grey-4;
        border: 0;

        @include breakpoints.px767 {
          position: relative;
        }
      }

      a,
      em {
        font-size: 1.25rem;
        font-family: "Chillax-Medium", sans-serif;
        font-weight: 500;
        font-style: normal;

        @include breakpoints.px767 {
          font-size: 1rem;
          display: block;
        }

        em {

          @include breakpoints.px767 {
            max-width: 90%;
          }

          &:last-of-type {
            margin-left: auto;
            font-family: "Poppins", sans-serif;
            font-weight: 400;

            @include breakpoints.px767 {
              margin: 0;
            }
          }
        }

        svg {
          margin-left: 0.8rem;

          @include breakpoints.px767 {
            margin: 0;
            position: absolute;
            top: 50%;
            right: 1.5em;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &__content {
    margin: 3rem 0 5rem 0;

    .p-title {

      h1 {
        font-size: 2rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 0.75rem;

        @include breakpoints.px767 {
          font-size: 1.25rem;
        }

        a {
          display: flex;
        }
      }
    }

    .press-release {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 1.5rem;
      margin-top: 3rem;

      @include breakpoints.px767 {
        margin-top: 1.31rem;
      }

      h2 {
        @extend %common-text-style;
        font-size: 1rem;
        font-weight: 600;
        padding: 0;
        max-width: 100%;
        margin: 0;
      }

      p {
        @extend %common-text-style;
        padding: 0;
        max-width: 100%;
        font-size: 1rem;

        strong {
          font-weight: 600;
        }

        &:first-of-type {
          margin-top: 0;
        }

        &:nth-last-child(2) {

          a {
            color: variables.$color-sky-blue;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            max-width: max-content;
            font-size: 1rem;
            display: block;
            padding: 0;
            margin: 0;
          }
        }
      }

      &__bottom {
        max-width: 1200px;
        margin: 0 auto;
        margin-top: 1.75rem;
        padding: 0 1.5rem;

        @include breakpoints.px767 {
          margin-top: 1.25rem;
        }

        &>p {
          background-color: variables.$color-off-white-1;
          padding: 1rem 1.5rem;
          display: flex;
          gap: 0.5rem;
          justify-content: space-between;

          &:last-of-type {
            margin-top: 0.5rem;
          }

          @include breakpoints.px1000 {
            flex-direction: column;
            justify-content: center;
            gap: 0.75rem;
          }

          button {
            cursor: pointer;
            height: max-content;
            align-self: center;
            background-color: transparent;
            border-radius: variables.$border-radius-8px;
            border: 2px solid variables.$color-light-black;
            padding: 0.5rem 1rem;
            display: flex;
            align-items: center;

            @include breakpoints.px1000 {
              justify-content: space-between;
              gap: 0 !important;
            }

            img {
              width: 100%;
            }

            span {
              font-size: 1rem;
              font-weight: 500;

              @include breakpoints.px767 {
                font-size: 0.875rem;
              }

              &:first-child {
                font-family: "Chillax-Medium", sans-serif;
                color: variables.$color-light-black;
              }

              &:last-child {
                font-family: "Poppins", sans-serif;
                color: variables.$color-dark-grey-6;
              }
            }

            @include breakpoints.px1000 {
              width: 100%;
            }
          }
        }
      }
    }
  }
}