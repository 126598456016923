@use "../common/variables";
@import "../common/breakpoints";

.dmca-container {
  padding: 20px 1rem 40px 1rem;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Chillax-medium";

  .dmca-top {

    h1 {
      color: variables.$color-purple;
      font-weight: 500;
      font-size: 64px;
      margin-bottom: 0;
    }

    h3 {
      color: variables.$color-purple;
      font-weight: 500;
      font-size: 32px;
      margin: 8px 0px 16px 0px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: variables.$color-dark-grey;
      font-family: "Poppins", sans-serif;
    }
  }

  .dmca-form-container {
    max-width: 620px;
    margin: 20px auto;
    margin-top: 30px;
    border-radius: variables.$border-radius-12px;
    border: 2px solid variables.$color-light-grey-4;
    box-shadow: 0px 2px 6px rgba(60, 0, 97, 0.08);
    padding: 20px 24px;

    .fields-required {
      font-family: "Poppins", sans-serif;
      margin-top: 12px;
    }

    .form-head {
      padding: 10px 0px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid variables.$color-light-grey-4;

      span {
        font-weight: 500;
        font-size: 18px;
        color: variables.$color-purple;
        color: variables.$color-dark-black;
      }

      .page-span {
        font-weight: 400;
        font-size: 16px;
        font-family: "Chillax-regular";
        margin-left: 12px;
      }

      img {
        margin-right: 10px;
      }
    }

    .body-container {
      margin: 0px 0px;

      p {
        font-weight: 400;
        font-size: 16px;
        color: variables.$color-dark-grey;
        font-family: "Poppins";
        margin-bottom: 1rem;
      }

      .form-body {
        padding: 10px 24px;

        label {
          display: inline-block;
          margin-bottom: 6px;
          font-weight: 500;
          font-size: 16px;
          color: variables.$color-light-black;
          font-family: "Chillax-medium";
        }

        &.original-work {
          border-bottom: 1px solid #E8E5F4;
          padding-bottom: 1rem;
        }

        &.signature {
          padding-left: 0;
        }
      }

      .checkbox-container {
        margin-bottom: 1rem;

        p {
          font-weight: 600;
          font-size: 16px;
          color: variables.$color-light-black;
          font-family: "Chillax-medium", sans-serif;
        }

        .checkbox-div {
          gap: 1rem;

          p {
            font-weight: 400;
            font-family: "Poppins", sans-serif;
            margin: 0;
          }
        }
      }

      .paragraph-div {
        p {
          font-weight: 400;
          font-size: 16px;
          font-style: italic;
          color: variables.$color-light-black;
        }
      }

      .paragraph-bold {
        p {
          font-weight: 600;
          font-size: 16px;
          color: variables.$color-light-black;
        }
      }

      .paragraph-light {
        font-weight: 400;
        font-size: 14px;
        color: variables.$color-dark-grey;
        // font-style: italic;
        margin: 0;
      }
    }

    .form-footer {
      user-select: none;
      display: flex;
      justify-content: space-between;
      padding: 20px 0px 0px;
      border-top: 1px solid variables.$color-light-grey-4;

      .footer-button {
        width: 30%;

        .back-button {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        button {
          font-family: "Chillax-Regular", sans-serif;
          font-weight: 500;
          font-size: 16px;
          width: max-content;

          img {
            margin-right: 10px;
            line-height: 50%;
            vertical-align: middle;
          }
        }

        .continue {
          width: 100%;
        }
      }
    }
  }
}

@include px767 {
  .dmca-container {
    padding: 20px 24px;

    .dmca-top {
      margin-bottom: 30px;

      h1 {
        font-size: 32px;
      }

      h3 {
        font-size: 20px;
        margin-bottom: 16px;
        margin-top: 8px;
      }
    }

    .dmca-form-container {
      width: 100%;
      margin-top: 32px;
      padding: 20px 18px;

      p {
        margin: 20px 0px 20px 0px;
      }

      .form-head {
        padding-top: 0px;
      }

      hr {
        margin: 20px 0px;
      }

      .body-container {
        p {
          margin: 20px 0px 15px 0px;
        }

        .checkbox-container {
          p {
            margin: 20px 0px 7px 0px;
            line-height: 24px;
          }

          .checkbox-div {
            padding: 8px 8px 8px 11px;
            border: 1px solid variables.$color-light-grey-4;
            border-radius: 8px;
            gap: 6px;
            align-items: start;

            input {
              margin-top: 7px;
            }

            p {
              margin: 0;
            }
          }
        }

        .paragraph-div {

          p {
            margin: 20px 0px 20px 0px;
          }
        }

        .paragraph-bold {
          p {
            margin: 20px 0px 20px 0px;
          }
        }

        .paragraph-light {
          p {
            margin: 6px 0px 20px 0px;
          }
        }
      }

      .form-body {
        padding: 5px 0px !important;
      }

      .form-footer {
        padding-top: 16px;
        margin-top: 15px;

        .footer-button {
          width: 50%;
        }
      }
    }
  }
}