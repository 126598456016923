@use "./variables";
@use "./breakpoints";

.info-note {
  background-color: #eae4d9;

  &__note {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2.5rem 1.5rem;

    p,
    a {
      color: variables.$color-maroon;
      font-weight: 500;
      font-size: 20px;
      font-family: "Poppins", sans-serif;

      @include breakpoints.px767 {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    a {
      text-decoration: none;
      color: #2076D3;
    }

    p {

      &:last-of-type {
        margin-top: 24px;
      }
    }

    span {
      &>a {
        color: variables.$color-light-orange;
        text-decoration: none;
        user-select: none;
      }
    }
  }
}