@use "../common/variables";
@use "../common/common";
@use "../common/breakpoints";

.privacy-info-breakdown {

  &__content {
    margin: 3rem 0 5rem 0;

    p {
      @extend %common-text-style;
      font-size: 1rem;
    }

    h2 {
      @extend %toc-heading;
      margin-top: 2.5rem;
    }

    a {
      color: variables.$color-sky-blue;
    }

    em {
      color: variables.$color-dark-grey;
    }

    ol,
    ul {
      @extend %common-text-style;
      font-size: 1rem;
      margin-top: 0;

      li {
        margin-left: 1.5rem;

        ul {
          margin-top: 0;
        }
      }
    }
  }
}